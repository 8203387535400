import {
  Button,
  Form,
  TextFieldControlled as Input,
  SelectFieldNativeControlled as Select,
  SelectFieldMinimal,
} from '@leafwell/components';
import type { SignupFormProps } from 'components/signup-form';
import { useBrazeContext } from 'contexts/braze-context';
import { useEventsTracker } from 'contexts/eventsTracker';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useMedicalCardParams } from 'hooks/useMedicalCardParams';
import { StatesListItem, useStatesList } from 'hooks/useStatesList';
import { setStateCookie } from 'lib/cookies/state-cookie';
import Script from 'next/script';
import { usePostHog } from 'posthog-js/react';
import { useMemo, useState } from 'react';

type MarketingFormProps = {
  formName: string;
  formSubmitText?: string;
  stateCode: string;
  label?: string;
  pageType?: string;
  utmParams?: SignupFormProps['utmParams'];
};

export const marketingForms = {
  waitinglistMC: 'waiting_list_mc',
  waitinglistCG: 'waiting_list_cg',
  signupPaused: 'signup_paused',
};

const FloridaDispensariesList = [
  'Ayr Cannabis Dispensary',
  'Cannabist',
  'Cookies Florida, Inc.',
  'Curaleaf',
  'FLUENT',
  'Goldflower Cannabis',
  'Green Dragon',
  'GrowHealthy',
  'GTI Florida, LLC',
  'House of Platinum Cannabis',
  'Insa',
  'Jungle Boys',
  'MUV',
  'Planet 13 Florida Cannabis for the Planet',
  'Sanctuary Cannabis',
  'Sunburn',
  'Sunnyside',
  'Surterra Wellness',
  'The Flowery',
  'Trulieve',
].map(dispensary => {
  return {
    label: dispensary,
    value: dispensary,
  };
});

export const getTemporaryUtmReferrerQuery = (): string => {
  const searchParams = new URLSearchParams(document.location.search);
  const utmReferrer = searchParams.get('utm_referrer');
  let referrer =
    document.referrer &&
    !new URL(document.referrer).pathname.startsWith('/medical-card')
      ? document.referrer
      : undefined;

  if (utmReferrer) {
    referrer = utmReferrer;
  }

  if (referrer) searchParams.set('utm_referrer', referrer);

  return !searchParams.size ? '' : `?${searchParams.toString()}`;
};

function FormSubmitMessage({ formName }) {
  if (
    formName === marketingForms.waitinglistMC ||
    formName === marketingForms.waitinglistCG
  ) {
    return (
      <div>
        <p>Thanks for joining our waitlist.</p>
        <p>
          You'll receive an email from us confirming that you've signed up
          shortly.
        </p>
        <p>As soon as we're operating in your state, we'll let you know.</p>
      </div>
    );
  }
  if (formName === marketingForms.signupPaused) {
    return (
      <div className="mb-1 leading-8 text-center">
        <p>Thank you for choosing Leafwell.</p>
        <p>One of our team will be in touch with you soon.</p>
      </div>
    );
  }
  return <p>Thank you for choosing Leafwell.</p>;
}

export default function MarketingForm({
  label,
  formName,
  formSubmitText,
  stateCode,
  pageType,
  utmParams,
}: MarketingFormProps) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const posthog = usePostHog();
  const statesList = useStatesList();
  const { trackEvent } = useEventsTracker();
  const medicalCardParams = useMedicalCardParams({
    initialUrl: '',
    params: utmParams,
  });
  const { saveUser: saveBrazeUser } = useBrazeContext();
  const { translations, cardPage, statePages } = useWordPressContext();
  function handleSelectState(stateCode?: string) {
    const hasStateCode = stateCode && stateCode !== '';
    const url = hasStateCode
      ? statePages.find(({ code }) => code === stateCode)?.uri
      : cardPage?.uri;

    if (hasStateCode) {
      setStateCookie(stateCode);
    }

    if (url) {
      window.location.assign(`${url}${getTemporaryUtmReferrerQuery()}`);
    }
  }

  async function handleSubmit({
    name,
    email,
    state,
    phone,
    zipCode,
    cardExpiryDate,
    dispensary,
  }) {
    // https://posthog.com/docs/product-analytics/identify#alias-assigning-multiple-distinct-ids-to-the-same-user
    posthog.alias(email);

    const floridaExtrafields =
      state === 'FL'
        ? {
            zip_code: zipCode,
            fl_waitlist_expiration_date: cardExpiryDate,
            fl_dispensary: dispensary,
          }
        : {};

    saveBrazeUser({
      email,
      name,
      phone,
      customAttributes: {
        state: translations[state.toUpperCase()],
        ...floridaExtrafields,
      },
    });
    trackEvent({
      event: 'form_submit',
      form_name: `formType_${window?.location?.pathname}`,
      form_destination: window?.location?.pathname,
      form_submit_text:
        formSubmitText && formSubmitText !== ''
          ? formSubmitText
          : translations['Submit'],
      formType: formName,
      ...medicalCardParams,
    });

    if (pageType && pageType === 'partners') {
      window.lintrk('track', { conversion_id: 11045548 });
    }

    setFormSubmitted(true);
  }

  const selectedStateObject: StatesListItem = useMemo(
    () => statesList.find(item => item.value === stateCode),
    [statesList, stateCode],
  );

  const floridaDefaultValues =
    stateCode === 'FL'
      ? {
          zipCode: '',
          cardExpiryDate: '',
          dispensary: '',
        }
      : {};

  return (
    <>
      {pageType && pageType === 'partners' && !window.lintrk && (
        <>
          <Script
            id="linkedin-script"
            dangerouslySetInnerHTML={{
              __html: `_linkedin_partner_id = "4464540";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
            }}
          />
          <Script
            id="ga-script"
            dangerouslySetInnerHTML={{
              __html: `(function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);})(window.lintrk);`,
            }}
          />
        </>
      )}
      <Form
        className="md:gap-x-3 gap-y-4"
        mode="onSubmit"
        defaultValues={{
          name: medicalCardParams?.name ?? '',
          email: medicalCardParams?.email ?? '',
          phone: medicalCardParams?.phone ?? '',
          state: stateCode,
          ...floridaDefaultValues,
        }}
        onSubmit={handleSubmit}
      >
        {formSubmitted ? (
          <FormSubmitMessage formName={formName} />
        ) : (
          <>
            <div className="grid gap-y-2">
              {label ? <p className="font-medium">{label}</p> : null}
              <SelectFieldMinimal
                aria-label="Select your state"
                defaultValue={selectedStateObject}
                placeholder={translations['State']}
                options={statesList}
                onSelect={target => handleSelectState(target.value)}
              />
            </div>
            {stateCode !== 'FL' ? (
              <div className="grid gap-y-2">
                <p className="font-medium">{translations['Name']}</p>
                <Input
                  className="rounded-lg"
                  name="name"
                  placeholder="Name (optional)"
                  type="text"
                />
              </div>
            ) : null}
            <div className="grid gap-y-2">
              <p className="font-medium">
                {translations['Email']} <sup>*</sup>
              </p>
              <Input
                className="rounded-lg"
                name="email"
                placeholder="Email *"
                type="email"
                validations={['required', 'email']}
              />
            </div>
            <div className="grid gap-y-2">
              <p className="font-medium">{translations['Cell Phone']}</p>
              <Input
                className="rounded-lg"
                name="phone"
                type="tel"
                placeholder="Cell Phone (optional)"
              />
              <p className="text-xs">
                {
                  translations[
                    "Please add an international code if your number isn't a US number"
                  ]
                }
              </p>
            </div>
            {stateCode === 'FL' ? (
              <>
                <div className="grid gap-y-2">
                  <p className="font-medium">
                    Zip Code <sup>*</sup>
                  </p>
                  <Input
                    className="rounded-lg"
                    name="zipCode"
                    placeholder="Zip Code *"
                    type="number"
                    validations={['required', 'zipcode']}
                  />
                </div>
                <div className="grid gap-y-2">
                  <p className="font-medium">
                    Expiry date of current card{' '}
                    <span className="font-normal">(optional)</span>
                  </p>
                  <Input
                    className="rounded-lg"
                    name="cardExpiryDate"
                    placeholder="Expiry date (optional)"
                    type="date"
                  />
                </div>
                <div className="grid gap-y-2">
                  <p className="font-medium">
                    Which dispensary do you shop at?
                  </p>
                  <Select
                    aria-label="Which dispensary do you shop at?"
                    name="dispensary"
                    placeholder="Choose your dispensary (optional)"
                    options={FloridaDispensariesList}
                    className="rounded-lg"
                  />
                </div>
              </>
            ) : null}
            <div className="mt-2">
              <Button
                type="submit"
                label={
                  formSubmitText && formSubmitText !== ''
                    ? formSubmitText
                    : translations['Submit']
                }
                name="submit"
                variant="secondary"
                className="w-full rounded-lg"
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
}
