import { useCurrentUtm } from './useCurrentUtm';

type UrlParams = Record<string, string>;

export type UpdateMedicalCardUrlProps = {
  initialUrl?: string;
  params?: UrlParams;
};

export function useMedicalCardParams(props?: UpdateMedicalCardUrlProps): {
  [key: string]: string | null;
} {
  if (typeof window === 'undefined') {
    return null;
  }

  const utmParams = useCurrentUtm(props?.params);
  // get any existing url params - can be used to autocomplete the signup form
  const urlSearchParams = new URLSearchParams(window.location.search);
  // remove state from query params - use defaultState value
  urlSearchParams.delete('state');
  const queryParams = Object.fromEntries(urlSearchParams);
  let initialUrlParams = {};

  if (props.initialUrl) {
    const url = new URL(props.initialUrl);
    initialUrlParams = Object.fromEntries(url.searchParams);
  }

  // utm inheritance order
  // window.location search params -> initialUrl search params -> default utm
  const params = {
    ...utmParams,
    ...initialUrlParams,
    ...queryParams,
  };

  return params;
}
